@import '@shared/assets/styles/mixins';
.custom-file-loader {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 48px;
    border: var(--container-border);
    border-radius: var(--border-radius);
  }

  &__no-image {
    color: var(--font-color-disabled);
  }
  &__actions {
    display: flex;
    gap: 8px;

    button {
      width: 48px;
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  &__trash path {
    fill: var(--error-color);
  }
  &__error {
    max-width: 220px;
    @include error();
  }
}
