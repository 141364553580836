.custom-clipped-text {
	overflow: hidden;
	display: flex;
	align-items: center;
	height: 100%;
	position: relative;

	&--overflown {
		.custom-clipped-text__content {
			opacity: 0;
		}

		> div:last-child {
			position: absolute !important;
			left: 0;
			max-width: 100%;
		}
	}

	&__content {
		white-space: nowrap;
		min-height: 1px;

		&--clipped {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
			line-height: initial;
		}
	}

	&__tooltip-content {
		word-wrap: break-word;
	}
}
