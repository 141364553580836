.company-participant-detail-users-table {
  padding: 32px 0;
  position: relative;

  &__person {
    display: flex;
    flex-direction: column;
    justify-items: start;
  }

  &__name {
    display: flex;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &__position {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--caption-text-color);
  }

  &__grid {
    border: none;
    border-radius: 0;
  }

  &__header {
    text-align: start;
    padding: 0 32px 32px 32px;
  }
}
