.proposals-list-report {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  text-align: start;
  padding: 30px;

  h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  p {
    font-size: 16px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  &__field {
    flex: 1;
    max-width: 608px;
  }
  &__submit {
    width: fit-content;
  }
}
