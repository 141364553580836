.detail-layout {
    &__nav {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    &__title {
        margin-bottom: 0;
        font-size: 24px;
    }
    &__back-btn {
        width: 36px;
        height: 36px;
        padding: 4px 8px;
        background-color: var(--gray-2-color);
    }
}