@import '@shared/assets/styles/mixins';

.participant-bond-detail {
  &__current-proposal {
    position: relative;
    .custom-info-box__col {
      width: 284px;
    }
  }
  &__nav-actions {
   @include actionButtons();
  }
  
  &__hint {
    color: var(--caption-text-color);
    font-size: 14px;
  }
}
