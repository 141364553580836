.admin-operators {
  display: flex;
  flex-direction: column;
  flex-grow: 0.85;
  &__actions {
    display: flex;
    gap: 24px;
    flex: 0 !important;
    margin-bottom: 18px;
  }
}
