@mixin flexableHeight {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.custom-table {
  @include flexableHeight;
  width: 100%;
  overflow: hidden;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  justify-content: center;
  background-color: var(--white-color);
  border: var(--container-border);
  border-radius: 12px;

  
  &__wrapper {
    position: relative;
    @include flexableHeight;
  }

  &__content {
    @include flexableHeight;
    min-height: 128px;
      &--no-data {
      display: flex;
      justify-content: center;
    }
  }
  &__loading {
    padding: 24px;
    overflow-x: auto;
    display: flex;
    flex: 1;
  }
}
