.position-detail-proposals {
  &__my-proposal {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    padding: 0 32px;
    
    .custom-container-field__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
