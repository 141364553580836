@import '@shared/assets/styles/mixins';

.custom-select-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  &--has-error {
    .custom-select__placeholder,
    .custom-select-label {
      color: var(--error-color) !important;
    }
    .custom-select__control {
      border: var(--input-border-error) !important;
    }
    .custom-select__control:hover {
      border: var(--input-border-hover-error) !important;
    }
    .custom-select__control:focus {
      border: var(--input-border-focused-error) !important;
    }
  }
  &__error {
    @include error();
  }
}

.custom-select-label {
  display: flex;
}
.custom-select {
  &__control {
    padding-left: 14px !important;
    padding-right: 6px !important;
  }

  &--is-multi {
    .custom-select__control {
      flex-wrap: nowrap !important;
    }
  }

  &__value-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    &--is-multi {
      flex-wrap: nowrap !important;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &--medium {
    .custom-select__control {
      @include mediumHeight();
    }
    .custom-select__placeholder,
    .custom-select__value-container {
      font-size: 14px;
    }
  }
  &--large {
    .custom-select__control {
      @include hugeHeight();
    }
    .custom-select__placeholder,
    .custom-select__value-container {
      font-size: 16px;
    }
  }

  &--is-chips {
    .custom-select__control {
      min-height: 48px !important;
      height: auto;
    }

    .custom-select__value-container--has-value {
      flex-wrap: wrap !important;
    }
    .custom-select__multi-value {
      background-color: #f2f2f2;
      border-radius: 8px;
      color: var(--primary-text-color);
      padding: 4px 8px 4px 8px;
      height: 32px;
    }

    .custom-select__multi-value__remove {
      color: #939393;
      padding: 0;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .custom-select__multi-value__remove:hover {
      background-color: transparent;
      color: var(--svg-icon-fill-hover);
    }
  }

  &__menu {
    z-index: 10 !important;
    margin-top: 0 !important;
    @include dropdownShadow();
  }
  &__placeholder {
    display: flex;
    color: var(--input-placeholder-color) !important;
  }
  &__multi-item {
    margin-right: 8px;
  }
  &__control {
    border: var(--border) !important;
    border-radius: var(--border-radius) !important;
    box-shadow: none !important;
    outline: none !important;
    min-height: auto !important;
  }
  &__single-value,
  &__multi-item {
    color: var(--primary-text-color) !important;
  }
  &__single-value {
    text-align: start;
  }
  &__single-value--is-disabled {
    color: var(--font-color-disabled) !important;
  }
  &__control--is-disabled {
    background-color: var(--input-disabled-bckg-color) !important;
    svg,
    path {
      fill: var(--font-color-disabled);
    }
  }
  &__control:hover {
    border-color: var(--input-hover-border-color) !important;
  }
  &__control:focus {
    border-color: var(--input-focused-border-color) !important;
    background-color: var(--input-focused-bckg-color) !important;
  }

  &__option {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;

    .custom-select-multi-option__text {
      padding-top: 4px;
    }
  }

  &__menu-list {
    .custom-select__option[role='option'] {
      background-color: var(--white-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
    .custom-select__option[role='option']:hover {
      background-color: var(--gray-4-color);
    }
    &:not(&--is-multi) {
      .custom-select__option[role='option']:active,
      .custom-select__option--is-selected[role='option'] {
        background-color: var(--gray-2-color) !important;
        font-weight: 500;
      }
    }
  }

  &__clear-indicator {
    cursor: pointer;
  }
  &__indicator-separator {
    display: none;
  }

  .custom-select-multi-value {
    &__selected-options {
      position: absolute;
    }
    &__tags {
      .custom-select__placeholder {
        position: absolute;
      }

      .custom-select__multi-value {
        display: none;
      }
    }
  }
}

.custom-select__menu-portal {
  z-index: var(--select-portal-z-index) !important;
}

.custom-select__option--is-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
