@font-face {
  font-family: 'Rubik';
  src: url('/shared/assets/fonts/Rubik-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/shared/assets/fonts/Rubik-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik';
  src: url('/shared/assets/fonts/Rubik-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/shared/assets/fonts/Rubik-LightItalic.ttf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/shared/assets/fonts/Rubik-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
