.communications-page {
  display: flex;
  &__decor,
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__decor {
    position: relative;
    &-mockup {
      z-index: 2;
    }
    &-icon {
      position: absolute;
      top: -10px;
      z-index: 1;
    }
  }

  &__content {
    h1 {
      font-size: 24px;
    }
    p {
      max-width: 600px;
      margin: 0;
      line-height: 24px;
    }
    &-action {
      margin-top: 32px;
      width: 220px;
    }
  }
}
.no-dialogs-module {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
