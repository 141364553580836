@import 'src/shared/assets/styles/variables';

.create-bond-proposal {
  &__title {
    text-align: left;
    margin-bottom: 28px;
  }
  &__columns {
    display: flex;
    gap: 60px;
  }
  &__row {
    display: flex;
    gap: 24px;
    align-items: center;
  }
  &__col {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: $block-width;
  }
  &__bonus {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__input-minicol {
    width: 125px;
  }
  &__input-minicol + .custom-input__container {
    width: inherit;
    flex: 1;
  }

  &__input {
    position: relative;
    flex: 1;

    &--label-left {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &--long-label {
      white-space: nowrap;
    }
  }

  &__required-hint {
    display: flex;
    padding: 32px 0 40px 0;
  }
  &__note {
    text-align: left;
    padding: 8px 16px;
    background-color: var(--gray-4-color);
    color: var(--caption-text-color);
  }
  &__comment {
    height: 124px;
  }
  &__actions {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
  }
}
