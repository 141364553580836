.custom-avatar {
  display: flex;
  &__img {
    object-fit: contain;
    width: auto;
    height: 32px;
  }
  &__circle {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  &__titles {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-left: 12px;
  }
  &__title {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &__description {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--caption-text-color);
  }
}
