.reset-password {
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding: 0 32px;
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      margin: 0;
    }
    .custom-input--has-error {
      margin-bottom: 16px;
    }
  }
  &__block--start {
    align-items: flex-start;
  }
  &__input {
    width: 336px;
  }
  &__btn,
  &__btn:hover {
    color: var(--finsage-primary-color);
  }
}

.register-page {
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding: 0 32px;
  }

  a {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    text-decoration-line: underline;
    color: var(--finsage-primary-color);
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    a {
      text-align: start;
    }
  }

  &__block--start {
    align-items: flex-start;
  }
}
