$paginationHeight: 72px;

.custom-table__wrapper .custom-pagination {
  font-size: 14px;
  padding: 20px 0px;
  height: $paginationHeight;
  display: flex;
  align-items: center;
  margin-left: auto;
  color: var(--primary-text-color);
  
  &__button {
    display: inline-flex;
    background: transparent;
  }

  &__arrow {
    width: 16px;
    height: 16px;
    & > svg {
      height: inherit;
    }
  }

  &-show-list {
    display: flex;
    align-items: center;
    width: 270px;
    margin-left: 64px;
    &__label {
      display: flex;
      flex-grow: 1;
      align-items: center;
      color: var(--caption-text-color);
    }
    &__select {
      width: 96px;
      margin: 0 12px;
    }
  }

  &__buttons {
    margin: 0 4px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    :disabled {
      path {
        fill: var(--font-color-disabled);
      }
    }
  }
}
