.support-page {
  & > .page-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    font-size: 24px;
    text-align: left;
  }

  &__actions {
    display: flex;
  }

  &__create-button {
    margin-left: 24px;
  }

  .date-time-cell {
    text-align: right;
    .time {
      color: var(--caption-text-color);
    }
  }
}
