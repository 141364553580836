.dialogs-report {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 16px;
  &__summary {
    border: 1px solid var(--border-light-color);
    display: flex;
    padding: 8px 0px;
    align-items: stretch;
    border-radius: 8px;
    margin-bottom: 36px;
  }
  &__summary-cell {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 26px;
  }
  &__summary-cell:not(:last-child) {
    border-right: 1px solid var(--border-light-color);
  }
  &__summary-label {
    font-size: 14px;
    font-weight: 400;
  }
  &__summary-value {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
  &__title {
    text-align: start;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &__row {
    display: grid;
    grid-template: 'a b c d e' 'f b g d h';
    grid-template-columns: 8fr 60px 1fr 60px 1fr;
    text-align: left;
    height: 72px;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #0000000a, 0px 0px 12px 0px #0000000a;
    padding: 16px 24px;
    margin-bottom: 8px;
  }
  &__icon {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &--incoming {
      background-color: rgba(238, 165, 82, 0.08);
    }
    &--outcoming {
      background-color: rgba(0, 101, 239, 0.08);
    }
  }
  &__cell {
    &--main {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 4px;
    }
    &--label {
      font-size: 12px;
      color: var(--caption-text-color);
    }
    &--icon {
      margin: auto 13px auto auto;
    }
  }
  .company-name {
    grid-area: a;
  }
  .last-dialog {
    grid-area: f;
  }
  .icon-input {
    grid-area: b;
  }
  .input-count {
    grid-area: c;
  }
  .input-count-label {
    grid-area: g;
  }
  .icon-output {
    grid-area: d;
  }
  .output-count {
    grid-area: e;
  }
  .output-count-label {
    grid-area: h;
  }
  &__table {
    flex: 1;
    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
