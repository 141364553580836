@import '@shared/assets/styles/mixins';

.paricipant-bond-detail {
  &__my-proposals {
    position: relative;
  }
  &__my-proposals-title {
    text-align: left;
    margin-bottom: 32px;
  }
  &__my-proposals-list {
    .custom-pills__list-item-link {
      display: block;
      text-align: left;
      background-color: var(--gray-3-color);
      width: 608px;
      padding: 12px 16px;
    }
    .custom-pills__list-item-link.active {
      background-color: rgba(0, 101, 239, 0.08);
      font-weight: 400;
      color: var(--primary-text-color);
    }
  }
  &__add {
    @include actionButtons();
  }
}

.bond-proposal-pill {
  display: flex;
  flex-direction: column;
  &__amount {
    font-weight: 500;
  }
  &__offer {
    color: var(--caption-text-color);
  }
}
