.similar-companies-modal {
  padding: 0 0 16px 0;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 260px;
    overflow-y: scroll;
  }

  &__company {
    border: var(--container-border);
    border-radius: var(--border-radius);
    background-color: var(--gray-2-color);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
  &__inn,
  &__role {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}
