@import '@shared/assets/styles/mixins';

.user-detail {
  .page-content {
    gap: 16px;
  }
  .user-detail-info {
    position: relative;
    &--fit-content {
      flex: 0;
    }
    &__row {
      display: flex;
      gap: 8px;
    }
    &__phone,
    &__mail {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &__company-role {
      font-size: 14px;
      color: var(--caption-text-color);
      margin-top: 1px;
    }
    &__actions {
      @include actionButtons();
      button {
        width: 36px;
        height: 36px;
        padding: 8px;
      }
    }
  }
}
.admin-user-notification {
  width: 360px;
  height: 216px;
  button {
    padding: 0;
  }
}
