.operator-bond-detail {
  & > .page-content {
    gap: 16px;
  }
  &__proposals {
    gap: 24px;
  }
  &__title-row {
    display: flex;
    padding: 32px 24px 24px 24px;
  }
  &__grid {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &__download {
    margin-left: auto;
  }
  &__extra-info {
    padding: 0;
  }
}
