.ticket-page {
  .page-content {
    gap: 16px;
  }

  .custom-info-box__col {
    width: unset !important;
  }

  &__content {
    display: flex;
    gap: 80px;
    > div {
      flex: 0.5;
    }
  }

  .ticket-info {
    &__main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    &__field {
      display: flex;
      flex-direction: column;
      svg {
        margin-right: 12px;
      }
      &-caption {
        color: var(--caption-text-color);
      }
    }

    &__action {
      display: flex;
      gap: 16px;
      &--decline {
        color: var(--negative-100-color);
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .custom-container-field,
  .custom-container-field__row {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .custom-container-field__row-value {
    text-align: left;
  }
}
