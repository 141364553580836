@import '@shared/assets/styles/mixins';

.dialogs {
  .active-dialogs {
    height: 680px;
    position: relative;
    overflow: hidden;
    padding-right: 4px !important;
    .title {
      font-size: 20px;
      text-align: start;
      position: sticky;
      margin-bottom: 24px;
    }
    &__list {
      height: 680px;
      .ReactVirtualized__Grid.ReactVirtualized__List {
        padding-right: 24px;
      }
    }
    .add {
      @include actionButtons();
    }
  }
}
