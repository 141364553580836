.position-detail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > .page-content {
    gap: 16px;
  }

  &-proposals {
    padding: 0;
    &__row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 32px;
      padding-bottom: 24px;
    }
    &__count {
      background-color: var(--background-color);
      padding: 8px 16px;
      border-radius: 6px;
      color: var(--caption-text-color);
    }
    &__nav {
      display: flex;
      gap: 16px;
    }
    &__nav-actions {
      display: flex;
      gap: 16px;
      button {
        padding: 8px;
      }
    }
    &--empty {
      display: flex;
      justify-content: center;
    }
  }
}

.modal-proposal-count-max {
  width: 424px;
}
