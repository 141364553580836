.page-layout {
  flex: 1;
}

.scrollable {
  position: absolute;
  top: var(--navbar-height);
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.page-content {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}
