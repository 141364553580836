@import 'src/shared/assets/styles/variables';

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-navbar {
  background-color: var(--white-color);
  box-shadow:
    0px 2px 4px 0px rgba(0, 0, 0, 0.04),
    0px 0px 12px 0px rgba(0, 0, 0, 0.04);

  &__logo {
    width: 64px;
    height: 32px;
    display: flex;
    align-items: center;
    overflow: visible;

    a {
      width: auto;
      height: 100%;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .nav-link {
    color: var(--primary-text-color) !important;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .nav-link:hover {
    background-color: var(--gray-4-color);
    border-radius: var(--border-radius);
  }

  @media (min-width: 1200px) {
    .navbar-nav {
      gap: 24px;
    }
  }

  .active {
    color: var(--primary-color) !important;
    border-radius: var(--border-radius);
    background: var(--gray-4-color);
    font-weight: 500;
  }
}

.custom-links {
  display: flex;
  flex-direction: row;
  gap: 16px;
  &__wrapper {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrapper:hover {
    background-color: var(--gray-4-color);
    border-radius: var(--border-radius);
    .nav-link {
      background-color: transparent;
    }
  }
  &__support {
    position: relative;
  }
  &__support-badge {
    position: absolute;
    background-color: var(--negative-100-color);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    outline: 2px solid #fff;
    top: 0px;
    right: 0px;
  }
  &__person {
    svg path {
      fill: var(--primary-color);
    }
  }
}
.credentials {
  display: flex;
  flex-direction: column;
  max-width: 180px;

  &__name {
    @include truncate();
    color: $layout-text-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    text-decoration: none;
  }

  &__company {
    @include truncate();
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    color: var(--caption-text-color);
  }
  &__person {
    svg path {
      fill: var(--primary-color);
    }
  }
}

.counter {
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  font-weight: 500;
  font-size: 10px;
  padding: 6px;
}
