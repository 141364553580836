@import '@shared/assets/styles/mixins';

.custom-radio-group-label {
  display: flex;
}

.custom-radio-group-wrapper {
  position: relative;
}

.custom-radio-group-wrapper--has-error {
  .custom-radio-button__label,
  .custom-radio-group-label {
    color: var(--checkbox-error-color) !important;
  }
}

.custom-radio-group {
  --spacing: 4px;

  &__error {
    @include error();
  }

  &--native {
    .custom-radio-button {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .custom-radio-button__input {
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      // dot
      background: var(--white-color);
      // spacing 
      border: var(--spacing) solid var(--white-color);
      // outer circle
      box-shadow: 0 0 0 1px var(--checkbox-border-color);
      margin-right: 8px;
    }

    .custom-radio-button__label {
      padding: 8px 8px 8px 0;
    }
    .custom-radio-button__input + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
    }


    /* стили при наведении курсора на радио */
    .custom-radio-button__input:not(:disabled):not(:checked):hover {
      box-shadow: 0 0 0 1px var(--checkbox-border-hover-color);
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .custom-radio-button__input:not(:disabled):active {
      box-shadow: 0 0 0 1px var(--checkbox-border-checked-color), var(--checkbox-focused-shadow);
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .custom-radio-button__input:focus {
      box-shadow: 0 0 0 1px var(--checkbox-border-checked-color), var(--checkbox-focused-shadow);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .custom-radio-button__input:focus:not(:checked) {
      box-shadow: var(--checkbox-focused-shadow);
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .custom-radio-button__input:checked {
      background: var(--checkbox-dot-color);
    }
    /* стили для радиокнопки, находящейся в состоянии disabled */
    .custom-radio-button__input:disabled {
      background-color: var(--checkbox-bckg-color-disabled);
      box-shadow: none;
      border: none;
    }
    /* стили для радиокнопки, находящейся в состоянии disabled и checked */
    .custom-radio-button__input:disabled:checked  {
      background: var(--white-color);
      box-shadow: none;
      border: var(--spacing) solid var(--checkbox-bckg-color-disabled);
    }
  }

  &--native.custom-radio-group--has-error {
       /* стили при наведении курсора на радио */
       .custom-radio-button__input:not(:disabled):not(:checked):hover {
        box-shadow: 0 0 0 1px var(--negative-60-color);
      }
  
      /* стили для активной радиокнопки (при нажатии на неё) */
      .custom-radio-button__input:not(:disabled):active {
        box-shadow: 0 0 0 1px var(--negative-100-color);
      }
  
      /* стили для радиокнопки, находящейся в фокусе */
      .custom-radio-button__input:focus {
        box-shadow: 0 0 0 1px var(--negative-100-color), var(--checkbox-focused-error-shadow);
      }
  
      /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
      .custom-radio-button__input:focus:not(:checked) {
        box-shadow: 0 0 0 1px var(--negative-100-color), var(--checkbox-focused-error-shadow);
      }
  
      /* стили для радиокнопки, находящейся в состоянии checked */
      .custom-radio-button__input:checked {
        background: var(--negative-100-color);
      }
  }
}
