@import '@shared/assets/styles/mixins';

.customer-detail-info {
  text-align: left;
  gap: 24px;
  position: relative;
  &__col {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__brand {
    display: flex;
    gap: 32px;
    margin-top: 8px;
  }
  &__actions {
    @include actionButtons();
    button {
      padding: 8px !important;
    }
  }
}
