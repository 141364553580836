.custom-modal-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.custom-modal-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 16px;
}

.custom-modal-actions {
  display: flex;
  gap: 8px;
}
