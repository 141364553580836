@import 'src/shared/assets/styles/variables';

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $layout-text-color;
  background-color: $layout-bg-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $layout-text-color;
}
