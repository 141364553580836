.button {
  color: var(--button-color);
  background-color: var(--button-bckg-color);
  border: var(--button-border);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  gap: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: 0.15s ease-out all;
  &--size {
    &-large {
      height: 48px;
      padding: 14px 16px;
      font-size: 16px;
    }

    &-medium {
      height: 36px;
      padding: 10px 16px;
      font-size: 14px;
    }

    &-small {
      height: 24px;
      padding: 0px 5px;
    }
  }

  &:hover {
    color: var(--button-color-hover);
    background-color: var(--button-bckg-color-hover);
    border-color: var(--button-border-color-hover);
  }

  &:active {
    color: var(--button-color-active);
    background-color: var(--button-bckg-color-active);
    border-color: var(--button-border-color-active);
  }

  &:focus {
    color: var(--button-color-focus);
    background-color: var(--button-bckg-color-focus);
    border-color: var(--button-border-color-focus);
  }

  &:disabled {
    color: var(--button-color-disabled);
    background-color: var(--button-bckg-color-disabled);
    cursor: not-allowed;
    opacity: 0.8;
    border: rgba(0, 0, 0, 0);

    & > img,
    & > svg {
      filter: invert(0.2);
    }
  }

  &--primary {
    color: var(--button-primary-color);
    background-color: var(--button-primary-bckg-color);
    border: var(--button-primary-border);

    &:focus,
    &:hover,
    &:active {
      color: var(--button-primary-color);
      background-color: var(--button-primary-bckg-color-hover);
      border: var(--button-primary-border);
    }

    &:disabled {
      color: var(--button-color-disabled);
      background-color: var(--button-bckg-color-disabled);
      cursor: not-allowed;
      opacity: 0.8;
      border: rgba(0, 0, 0, 0);

      & > img,
      & > svg {
        filter: invert(0.2);
      }
    }
  }
  &--brand {
    color: var(--button-primary-color);
    background-color: var(--finsage-primary-color);
    border: var(--finsage-primary-color);

    &:focus,
    &:hover,
    &:active {
      color: var(--button-primary-color);
      background-color: var(--finsage-primary-color);
      border: var(--finsage-primary-color);
    }

    &:disabled {
      color: var(--button-color-disabled);
      background-color: var(--button-bckg-color-disabled);
      cursor: not-allowed;
      opacity: 0.8;
      border: rgba(0, 0, 0, 0);
    }
  }

  &--text {
    color: var(--primary-color);
    border: none;
    background: transparent;

    &:hover {
      color: var(--secondary-color);
    }

    &:active {
      color: var(--secondary-color);
      border: none;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--without-border {
    border: none;
    background: var(--gray-2-color);

    &:hover {
      background: var(--gray-4-color);
    }
  }

  &--warning {
    color: var(--negative-100-color);

    &:hover {
      color: var(--negative-100-color);
    }
  }

  img,
  svg {
    pointer-events: none;
    padding: 0;
    border: none;
    background-color: transparent;
    place-self: center;
    font-size: 24px;
  }

  &--with-size {
    padding: 0 !important;
  }

  &__loader {
    width: 12px;
    height: 12px !important;
    margin-right: 4px;
  }
}
