// layout
$layout-bg-color: var(--background-color);
$layout-text-color: var(--primary-text-color);

// header
$header-bg-color: var(--white-color);

// footer
$footer-text-color: var(--caption-text-color);

$block-width: 608px;