.custom-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    gap: 24px;

    &-item {
      &-link {
        padding: 8px 12px;
        color: var(--caption-text-color);
        text-decoration: none;
        font-weight: 500;
        border-bottom: 2px solid transparent;

        &:hover {
          color: var(--primary-text-color);
        }
        &.active {
          color: var(--primary-text-color);
          border-bottom: 2px solid var(--primary-color);
        }
        &.disabled {
          color: var(--font-color-disabled);
          border-bottom: none;
          pointer-events: none;
        }
      }
    }
  }
}
