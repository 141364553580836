@import '@shared/assets/styles/mixins';

.custom-datepicker-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  &--has-error {
    .custom-datepicker-label,
    input::placeholder {
      color: var(--error-color);
    }
    input:not(.custom-datepicker-wrapper--has-error input:hover):not(
        .custom-datepicker-wrapper--has-error input:focus
      ) {
      color: var(--error-color);
      border: var(--input-border-error);
    }
    input:hover {
      color: var(--error-color) !important;
      border: var(--input-border-hover-error) !important;
    }
    input:focus-visible {
      outline: none;
      color: var(--error-color);
      border: var(--input-border-focused-error);
    }
  }
  &__error {
    @include error();
  }
}
.custom-datepicker-label {
  display: flex;
}
.custom-timepicker {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 0 20px;
  &__inputs {
    display: flex;
    justify-content: center;
    gap: 18px;
    padding: 20px 55px;
  }
  &__actions {
    padding-top: 4px;
    display: flex;
    gap: 8px;
    justify-content: center;
  }
  &__actions--divider {
    border-top: var(--container-border);
  }
}
.custom-datepicker {
  &--medium {
    input {
      @include mediumHeight();
      font-size: 14px;
    }
    svg {
      width: 14px;
      height: 16px;
    }
  }
  &--large {
    .react-datepicker__calendar-icon {
      top: 6px;
    }
    input {
      @include hugeHeight();
      font-size: 16px;
    }
    svg {
      width: 16px;
      height: 18px;
    }
  }
  &--disabled {
    input::placeholder {
      color: var(--font-color-disabled);
    }
    svg,
    path {
      fill: var(--font-color-disabled);
    }
  }
  input {
    width: 100%;
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px;
    gap: 12px;
    border: var(--input-border);
  }
  input:focus-visible:not(.custom-datepicker-wrapper--has-error input) {
    border: 1px solid var(--input-focused-border-color);
    background-color: var(--gray-2-color);
    outline: none;
  }
  input::placeholder {
    color: var(--input-placeholder-color);
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 6px;
  }
  .react-datepicker__close-icon {
    right: 38px;
  }
  .react-datepicker__close-icon:after {
    background-color: transparent !important;
    content: url('../../../shared/icons/close.svg') !important;
    outline: none;
  }
}
.custom-datepicker-popper {
  padding-top: 0 !important;
  @include dropdownShadow();

  .react-datepicker {
    font-family: 'Rubik' !important;
    border: none;
    color: var(--primary-text-color);
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {
    width: 280px;
    min-height: 327px;
    padding: 16px 0px;
    border-radius: 8px;
    gap: 18px;
    background-color: var(--white-color);
  }
  .react-datepicker-time__caption {
    display: none;
  }
  .custom-datepicker-header__button {
    border: none;
    outline: none;
    background-color: transparent;
  }

  .custom-datepicker-header {
    display: flex;
    justify-content: space-between;
    font-family: 'Rubik';
    padding: 16px;

    &__label {
      font-family: 'Rubik';
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      display: flex;
      align-items: center;
    }
    &__arrows {
      display: flex;
      gap: 28px;
    }
  }
  .react-datepicker__header {
    border-bottom: var(--container-border);
  }
  .react-datepicker__header--custom {
    background-color: var(--white-color);
    padding: 0;
  }
  .react-datepicker__day-names {
    margin-bottom: 4px;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 32px;
    height: 32px;
    border: 2px solid transparent;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 16px;
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-weight: bold !important;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border: 2px solid var(--secondary-color);
    color: var(--primary-color);
    background-color: var(--gray-4-color);
    border-radius: 16px;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    border: 1px solid var(--secondary-color);
    border-radius: 16px;
    font-weight: normal;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    border-color: transparent !important;
    background-color: transparent !important;
    color: var(--disabled-text-color);
  }
  .react-datepicker.react-datepicker--time-only {
    .react-datepicker-time__input {
      margin: 0;
    }
    width: 265px;
    border-radius: 8px;
    gap: 18px;
  }
  .react-datepicker__input-time-container,
  .react-datepicker-time__input {
    float: none;
    margin: 0;
  }

  .react-datepicker__day--outside-month {
    color: var(--disabled-text-color);
  }
}
.react-datepicker__tab-loop {
  position: absolute;
}
.react-datepicker-popper {
  z-index: 2;
}
