.auth-layout {
  position: relative;
  background: url('../../../shared/icons/authPage/background.svg') no-repeat;
  background-color: var(--background-color);
  background-size: cover;
  flex: 1;
  flex-direction: column;
  display: flex;

  &-header {
    box-sizing: content-box;
    display: flex;
    justify-content: flex-start;
    padding: 40px;
    height: 40px;
    svg {
      width: 230px;
      height: 40px;
    }
  }
  &__content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
  }
  &__container {
    display: flex;
    flex-direction: column;
    padding: 40px 32px;
    background-color: var(--white-color);
    border-radius: 12px;
    gap: 32px;
    box-shadow:  0px 2px 4px 0px #0A20450A, 0px 0px 16px 0px #113A8114;
    max-width: 400px;
  }
}
