.request-detail-group {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > .page-content {
    gap: 16px;
  }
}

.request-detail-group-info {
  text-align: left;
  gap: 24px;
  flex: 0 1 308px;
}

.request-detail-group-table {
  padding: 0;

  &__grid {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &__details {
      .custom-cell {
        flex-direction: column;
        text-align: center;
      }
    }
  }

  &__row {
    display: flex;
    gap: 36px;
    width: 100%;
    padding: 32px;
    padding-bottom: 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    color: var(--primary-text-color);
  }

  &__download,
  &__communication {
    margin-left: auto;
  }

  &__button-next {
    padding: 4px 8px;
  }

  &__detail-cell-value {
    text-align: center;
  }
  .custom-cell:has(&__detail-cell-value) {
    flex-direction: column;
  }

  &__detail-cell-rate {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
  &__detail-cell-rate--best {
    font-weight: bold;
  }
  &__detail-cell-participant {
    font-size: 12px;
    font-weight: 500;
  }
  &__label {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-text-color);
  }
}

.request-detail-group {
  &__process-buttons {
    display: flex;
    gap: 20px;
    flex-basis: 128px;
    margin-top: 8px;

    &-remind {
      width: 220px;
    }
  }

  &__notification-warning-text {
    font-size: 16px;
    font-weight: 500;
  }

  &__notification-comment-wrapper {
    margin: 16px 0 28px 0;
  }

  &__notification-comment {
    height: 160px;
  }
}
