.active-dialogs {
  padding: 30px;

  &__list-item {
    display: flex;
    width: 100%;
    &-wrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
    }
    &-link {
      width: 100%;
      flex: 1;
      align-items: center;
      display: flex;
      padding: 8px 12px;
      color: var(--caption-text-color);
      background-color: var(--background-color);
      text-decoration: none;
      border-radius: 6px;
      height: 68px;
      svg {
        align-self: center;
      }
      &:hover {
        color: var(--primary-text-color);
        background-color: #ebf3fe;
      }
      &.active {
        background-color: #ebf3fe;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      text-align: start;
      margin-left: 16px;
      gap: 4px;
    }
    &-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-text-color);
      display: flex;
      align-items: center;
    }
    &-name--marked::after {
      display: block;
      background-color: #e65757;
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: 6px;
    }
  }
  &__list-item-topic {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 24px;
  }
}
