@import '@shared/assets/styles/mixins';

.personal-info {
  &__edit {
    @include actionButtons();
  }
  &__exit {
    width: 170px;
    padding: 0;
  }

  .user-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .custom-input__error {
      position: relative !important;
      bottom: unset;
    }

    label {
      margin-bottom: 4px;
    }

    &__title {
      margin-bottom: 20px;
    }
    &__row {
      display: flex;
      gap: 20px;
      > div {
        flex: 0.5;
      }
    }
    &__warning {
      color: var(--caption-text-color);
    }
    &__disabled-fields {
      text-align: right;
    }

    &__actions {
      display: flex;
      gap: 16px;
      button {
        width: 140px;
      }
    }
    &__request-type-select--no-option {
      .custom-select__control {
        background-color: #22222205;
        color: var(--font-color-light);
      }
    }
  }
}
