.custom-not-found {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 64px 0;
  flex: 1;
  gap: 12px;
  height: 100%;

  &__icon {
    margin-inline: auto;
    max-width: 64px;
    max-height: 64px;
    object-fit: cover;
  }
  &__message {
    color: var(--light-text-color);
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
  }

  &__description {
    color: var(--light-text-color);
    font-size: 16px;
    line-height: 16px;
  }
}
