.log {
  display: flex;
  flex-direction: column;
  flex-grow: 0.85;
  &__actions {
    display: flex;
    gap: 12px;
    padding: 20px 0;
    flex-wrap: wrap;
  }

  .time {
    color: var(--caption-text-color);
    text-align: right;
  }

  .creator {
    display: flex;
    flex-direction: column;
    &__company {
      color: var(--caption-text-color);
    }
  }

  .recipient_block {
    display: flex;
    flex-direction: column;
    .recipient {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      &__email {
        color: var(--caption-text-color);
      }
    }
    .recipient:last-child {
      margin-bottom: 0;
    }
  }
}
