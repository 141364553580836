@import '@shared/assets/styles/mixins';
@import '@shared/assets/styles/variables';

.new-request {
  height: calc(100vh - 52px);

  .page-content {
    overflow-y: scroll;
    gap: 16px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
  }
  &__block-title {
    font-size: 20px;
    text-align: left;
  }
  &__details-box {
    margin-bottom: 16px;
    flex-direction: row !important;
    height: 770px;
  }

  &__details {
    display: flex;
    width: $block-width;
    flex-direction: column;
    justify-items: flex-start;
    gap: 24px;
  }
  &__positions-error {
    color: var(--error-color);
  }
  &__positions-date-warning {
    color: var(--error-color);
    text-align: left;
    margin-top: 12px;
  }
  &__positions-actions {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
  &__row {
    display: flex;
    gap: 24px;
  }
  &__rate-type {
    display: flex;
    flex-direction: column;
  }
  &__rate-type-inputs {
    display: flex;
    gap: 24px;
  }
  &__date,
  &__amount,
  &__currency {
    flex: 1;
  }
  &__date .custom-datepicker-wrapper__error {
    width: max-content;
  }
  &__positions-list {
    list-style-type: decimal-leading-zero;
    margin: 24px 0 !important;
  }
  &__required-hint {
    display: flex;
  }
  &__actions {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin: 28px 0 60px 0;
  }
  &__hint {
    margin-left: 80px;
  }
}

.new-request-modal {
  &__content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
  }
  button {
    width: 172px;
  }
}
