.tickets-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  h1 {
    font-size: 24px;
    text-align: left;
    margin-top: 16px;
  }
  .tickets {
    display: flex;
    flex-direction: column;
    flex-grow: 0.85;
    &__actions {
      display: flex;
      gap: 12px;
      padding: 20px 0;
      flex-wrap: wrap;
    }

    .time {
      color: var(--caption-text-color);
      text-align: right;
    }

    .creator {
      display: flex;
      flex-direction: column;
      &__company {
        color: var(--caption-text-color);
      }
    }
  }

  .date-time-celll {
    text-align: right;
  }
}
