.info-page {
  position: relative;
  text-align: left;
  font-size: 14px;

  &__html {
    max-width: 700px;
  }

  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
    margin-top: 32px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
}

.info-page-table {
  border-collapse: collapse;

  td {
    padding: 8px;
    border: var(--border);
  }

  tr td:first-child {
    background-color: var(--gray-4-color);
  }
}

.info-page-operator-table {
  tr td:first-child {
    text-align: center;
    width: 64px;
  }
}

.info-page-menu-bar {
  width: 360px;
  height: min-content;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--gray-2-color);
  position: sticky;
  top: 64px;
  left: 100%;
  text-align: left;
  font-size: 16px;

  ul {
    list-style: none;
    padding: 32px 24px;
  }

  &__item {
    padding-bottom: 20px;
    padding-left: 16px;
    cursor: pointer;
    position: relative;

    &--active {
      font-weight: 700;
    }

    &-icon {
      position: absolute;
      left: 0;
      height: 20px;
    }
  }
}
