@import '@shared/assets/styles/variables';

.new-customer {
  .page-content {
    gap: 16px;
  }
  &__details-box--edited {
    border: 0;
    margin: 0;
    margin-bottom: 32px;
    padding: 0;
  }
  &__row {
    display: flex;
    flex: 1;
    max-width: 1376px;
    gap: 60px;
  }
  &__col {
    width: $block-width;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
  }
  &__block-title {
    text-align: start;
  }
  &__required-hint {
    display: flex;
    margin: 28px 0 0 0;
  }
  &__logo {
    margin-top: 20px;
  }
  &__actions {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin: 28px 0 0 0;
  }
  &__action-button {
    width: 220px;
  }
}
