.custom-filters {
  display: flex;
  flex-grow: 1;
  gap: 16px;

  &__range {
    margin-right: 24px;
  }
  &__companies,
  &__statuses {
    width: 320px;
  }
  .custom-input {
    width: 280px !important;
    margin-left: auto;
  }
}
