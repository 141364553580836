@import '@shared/assets/styles/mixins';

.custom-input {
  $paddingAfterLeftIcon: 40px;

  position: relative;
  &__label {
    display: flex;
    text-align: start;
  }
  &__container {
    display: flex;
    position: relative;
    width: 100%;
    height: 36px;
    border-radius: var(--border-radius);
    padding: 6px 12px;
    border: var(--input-border);
    background-color: var(--input-bckg-color);

    &:focus-within {
      border-color: var(--input-focused-border-color);
      outline: none;
      background-color: var(--input-focused-bckg-color);
    }
    input:focus {
      background-color: transparent;
    }
    &--disabled {
      border: 1px solid var(--input-disabled-border-color);
      color: var(--input-disabled-color);
      cursor: not-allowed !important;
      background-color: var(--input-disabled-bckg-color) !important;
    }
    &:hover:not(:focus-within):not(.custom-input__container--disabled):not(.custom-input__container--has-error) {
      border-color: var(--input-hover-border-color);
    }
    &--has-left-icon {
      padding-left: $paddingAfterLeftIcon !important;
    }
  }
  &--medium {
    .custom-input__container {
      @include mediumHeight();
    }
    .custom-input__floating-label,
    .custom-input__field {
      font-size: 14px !important;
    }
    .custom-input__custom-icon {
      left: 12px;
      width: 16px;
      height: 16px;
    }
    .custom-input__clear-button {
      top: 12px;
      right: 12px;
      width: 10px;
      height: 10px;
    }
  }
  &--large {
    .custom-input__container {
      @include hugeHeight();
    }
    .custom-input__floating-label,
    .custom-input__field {
      font-size: 16px !important;
    }
    .custom-input__custom-icon {
      top: 12px;
      left: 12px;
      width: 20px;
      height: 20px;
    }
    .custom-input__clear-button {
      top: 17px;
      right: 17px;
      width: 10px;
      height: 10px;
    }
  }
  :focus-visible {
    border: none !important;
    outline: none !important;
    box-shadow: none;
  }

  & &__field {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: none;
    font-size: var(--input-font-size);
    vertical-align: text-bottom;
    line-height: 1;

    &input,
    &textarea {
      width: 100%;
      line-height: 1 !important;
      border: none;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--input-color);
    }
  }

  &__floating-label {
    position: absolute;
    opacity: 1;
    z-index: 1;
    pointer-events: none;
    left: 14px;
    top: calc(50% - 0.7em);
    font-size: var(--input-font-size);
    line-height: 1.5;
    color: var(--input-placeholder-color);
    transition: 0.1s ease all;
    &--after-icon {
      left: $paddingAfterLeftIcon + 2px;
    }
    &--transparent {
      opacity: 0;
    }

    &--focused {
      opacity: 0;
      top: 0;
    }
  }

  &:not(&--empty) &__icon {
    visibility: hidden;
  }
  &:not(&--has-label) {
    input,
    textarea {
      padding-top: calc(12px / 3);
    }
  }
  &--has-label &__icon,
  &--has-label &__clear-button {
    margin-top: calc(12px * -0.5);
  }

  &--disabled > &__floating-label {
    color: var(--input-disabled-label-color);
  }
  &--disabled &__clear-button {
    display: none;
  }

  &--required > &__floating-label:after {
    content: '*';
    color: var(--secondary-color);
    margin-left: 3px;
  }

  &--has-error &__container {
    border: var(--input-border-error);
  }
  &--has-error &__container:focus-within {
    border: var(--input-border-focused-error) !important;
  }
  &--has-error &__container:hover {
    border: var(--input-border-hover-error) !important;
  }
  &--has-error &__floating-label,
  &--has-error &__label,
  &--has-error input {
    color: var(--error-color);
  }
  &__error {
    @include error();
  }
  &__error-left {
    @include error(flex-start);
  }

  &__icon {
    fill: var(--svg-icon-fill);
  }

  & &__clear-button {
    position: absolute;

    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    height: unset;

    svg {
      fill: var(--svg-icon-fill);
      width: 100%;
      height: 100%;
    }

    &:hover svg {
      fill: var(--svg-icon-fill-hover);
      background: var(--input-bckg-color);
    }

    &:active svg,
    &:focus svg {
      background: var(--input-bckg-color);
    }
  }

  &:focus-within > &__floating-label {
    display: none;
  }

  &__custom-icon {
    position: absolute;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
