.profile-page {
  &__main {
    display: flex;
    flex-direction: row;
    gap: 28px;
    margin-bottom: 24px;
  }
  &__name,
  &__company {
    text-align: left;
  }
  &__names {
    display: flex;
    flex-direction: column;
  }
  &__name {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__company {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: var(--caption-text-color);
  }
  &__avatar {
    width: 88px;
    height: 88px;
  }

  &__row {
    display: flex;
    gap: 16px;
  }
  &__box {
    text-align: left;
    gap: 24px;
    position: relative;
  }

  &__security {
    display: flex;
    flex-direction: column;
    gap: 20px;
    button {
      margin-bottom: 4px;
    }
    span {
      color: var(--caption-text-color);
    }
  }
}
