.loader-overlay {
  position: absolute;
  top: 0; // var(--navbar-height);
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  background-color: rgba(250, 250, 250, 0.8);
  color: var(--primary-color);
  z-index: var(--loader-overlay-z-index, 100000);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-loader {
  width: 60px;
  height: 60px;
}
// TODO: заменить анимацию
.circular {
  animation: rotate 2s linear infinite;
}

.path {
  stroke: var(--primary-color);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
