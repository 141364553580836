.custom-notification {
  width: 360px;
  background-color: var(--modal-notification-bckg-color);

  &__message {
    padding-bottom: 28px;
    margin: 0;
    font-size: 16px;
    line-height: 1.49em;
    letter-spacing: 0.25px;
    white-space: pre-line;
    word-wrap: break-word;
  }

  &__header {
    display: flex;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
  &__wrapper-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &__actions {
    gap: 16px;
  }
}
