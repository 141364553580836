.dialogs {
  &__send {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    padding: 0 16px 28px 16px;
    path {
      fill: var(--primary-color);
    }
  }
  .chat-footer {
    &__message {
      flex: 1;
    }
  }
}
