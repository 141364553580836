input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.custom-number-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;

  &__button {
    outline: none;
    -webkit-appearance: none;
    background-color: var(--gray-2-color);
    border: none;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 36px;
    cursor: pointer;
    margin: 0;
    position: relative;
    border-radius: 8px;
  }
  &__button:hover, &__button:focus {
    background-color: var(--gray-4-color);
  }

  &__quantity {
    font-family: 'Rubik';
    width: 48px !important;
    height: 36px;
    border: var(--input-border);
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 14px;
    text-align: center;
  }
  &__quantity:focus-within {
    border-color: var(--input-focused-border-color);
    outline: none;
    background-color: var(--input-focused-bckg-color);
  }
  &_quantity:hover {
    border-color: var(--input-hover-border-color);
  }
}
