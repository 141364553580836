.dialog-message {
  text-align: start;
  background-color: var(--background-color);
  padding: 12px 16px;
  width: fit-content;
  &__name {
    font-weight: 500;
  }
  &__date {
    color: var(--caption-text-color);
    font-size: 14px;
  }
  &--incoming {
    border-radius: 0 8px 8px 8px;
    align-self: flex-start;
  }

  &--outcoming {
    border-radius: 8px 8px 0 8px;
    align-self: flex-end;
  }

  &--my-company {
    background-color: rgba(0, 101, 239, 0.08);
    .dialog-message__name {
      color: var(--clean-blue);
    }
  }

  &--partner {
    background-color: rgba(238, 165, 82, 0.08);
    .dialog-message__name {
      color: rgba(238, 165, 82, 1);
    }
  }
}
