.create-dialog-modal {
  width: 376px;
  height: 372px;

  &__title {
    font-size: 20px;
    line-height: 28px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 18px;
  }
}
