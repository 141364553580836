.custom-tooltip {
  .tooltip-body {
    z-index: 3;
    position: fixed;
    padding: 8px;
    background: var(--gray-80-color);
    color: var(--white-color);
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
  }
}
