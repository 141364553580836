.request-atomic {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > .page-content {
    gap: 16px;
  }
}

.request-atomic-info {
  text-align: left;
  gap: 24px;
  flex: 0 1 400px;
}

.request-atomic-table {
  padding: 0;

  &__grid {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  &__row {
    display: flex;
    gap: 36px;
    width: 100%;
    padding: 32px;
    padding-bottom: 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    color: var(--primary-text-color);
    font-size: 20px;
    font-weight: 500;
  }

  &__download {
    margin-left: auto;
  }

  &__button-next {
    padding: 4px 8px;
  }

  &__detail-cell-value {
    text-align: center;
  }

  &__detail-cell-rate {
    font-size: 12px;
    font-weight: 400;
    color: var(--caption-text-color);
  }
}

.request-atomic {
  &__process-buttons {
    display: flex;
    gap: 20px;
    flex-basis: 128px;
  }
  &__empty-box {
    flex-basis: 32px;
  }
}
