@import '@shared/assets/styles/mixins';

.user-detail-table {
  padding: 32px 0;
  position: relative;

  &__title {
    text-align: start;
    padding: 0 0 32px 32px;
  }

  &__member {
    display: flex;
    flex-direction: column;
    justify-items: start;
  }

  &__name {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &__inn {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--caption-text-color);
  }

  &__grid {
    border: none;
    border-radius: 0;
  }

  &__header {
    text-align: start;
    padding: 0 32px 32px 32px;
  }

  &__actions {
    @include actionButtons();
    button {
      padding: 8px !important;
    }
  }

  &__edit-actions {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    margin-left: 32px;
  }
}
