@import '@shared/assets/styles/mixins';

.chat-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border-light-color);
  padding: 16px;
  &__main {
    display: flex;
    flex: 1;
    gap: 8px;
  }
  &__name {
    font-weight: 500;
    font-size: 14px;
  }
  &__date {
    color: var(--light-text-color);
    font-size: 12px;
  }
  &__topic {
    text-align: start;
    color: var(--caption-text-color);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.linked-requests {
  height: 0px;
  border-bottom: 1px solid var(--border-light-color);
  background-color: var(--background-color);
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--caption-text-color);
  overflow-y: hidden;

  &__link {
    color: var(--caption-text-color);
    display: inline-block;
  }
  &__link:not(:first-child) {
    margin-left: 4px;
  }
  &__icon {
    flex-shrink: 0;
  }
  &__list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__link:hover,
  &__link:active {
    color: var(--primary-text-color);
  }
}
