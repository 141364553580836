.company-participant-form {
  &__box {
    margin: 16px 0;

    &--edited {
      border: 0;
      margin: 0;
      margin-bottom: 32px;
      padding: 0;
    }
  }

  &__fields-wrap {
    width: 618px;
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &__title {
    text-align: left;
    margin: 0;
    font-size: 20px;
  }

  &__actions {
    display: flex;
    gap: 20px;
  }

  &__action-button {
    width: 220px;
  }

  &__hint {
    text-align: left;
  }
}
