:root {
  // brand
  --primary-color: #222222;
  --secondary-color: #313131;

  // finsage-brand
  --finsage-primary-color: #113a81;
  --finsage-secondary-color: #3f84c5;

  // text
  --primary-text-color: #222222;
  --caption-text-color: #939393;
  --light-text-color: #c4c4c4;
  --disabled-text-color: #d4d4d4;

  // assistive
  --border-bright-color: #cdcdcd;
  --border-light-color: #ebebeb;
  --background-color: #fafafa;
  --white-color: #ffffff;
  --clean-blue: #0065ef;

  // negative
  --negative-100-color: #e65757;
  --negative-90-color: rgba(230, 87, 87, 0.9);
  --negative-60-color: rgba(230, 87, 87, 0.6);
  --negative-12-color: rgba(230, 87, 87, 0.12);
  --negative-2-color: rgba(230, 87, 87, 0.02);

  // grayscale
  --gray-80-color: rgba(34, 34, 34, 0.8);
  --gray-60-color: rgba(34, 34, 34, 0.6);
  --gray-30-color: rgba(0, 0, 0, 0.3);
  --gray-12-color: rgba(34, 34, 34, 0.12);
  --gray-4-color: rgba(34, 34, 34, 0.04);
  --gray-3-color: rgba(34, 34, 34, 0.03);
  --gray-2-color: #fbfbfb; // rgba(34, 34, 34, 0.02);

  // state
  --error-color: var(--negative-100-color);
  --block-background-color: #fff;

  // --font-colors
  --font-color-light: #c4c4c4;
  --font-color-disabled: var(--disabled-text-color);

  --border-color: var(--border-bright-color);
  --border: 1px solid var(--border-color);

  --border-radius: 8px;

  --container-border: 1px solid var(--border-light-color);

  --checkbox-bckg-color: var(--white-color);
  --checkbox-border-color: var(--border-color);
  --checkbox-border-hover-color: var(--primary-color);
  --checkbox-border-checked-color: var(--primary-color);
  --checkbox-focused-shadow: 0 0 0 2px var(--gray-12-color);
  --checkbox-focused-error-shadow: 0 0 0 4px var(--negative-12-color);
  --checkbox-error-color: var(--negative-100-color);
  --checkbox-bckg-color-disabled: var(--gray-12-color);
  --checkbox-dot-color: var(--primary-color);

  --input-border: var(--border);
  --input-color: var(--primary-text-color);
  --input-bckg-color: var(--white-color);
  --input-font-size: 14px;
  --input-placeholder-color: var(--light-text-color);
  --input-label-color: var(--light-text-color);
  --input-disabled-label-color: var(--disabled-text-color);
  --input-disabled-color: var(--disabled-text-color);
  --input-disabled-bckg-color: var(--gray-4-color);
  --input-disabled-border-color: var(--border-bright-color);
  --input-border-error: 1px solid var(--negative-60-color);
  --input-border-hover-error: 1px solid var(--negative-90-color);
  --input-border-focused-error: 1px solid var(--negative-100-color);
  --input-icon-color: var(--svg-icon-fill);
  --input-hover-border-color: var(--secondary-color);
  --input-focused-bckg-color: var(--gray-2-color);
  --input-focused-border-color: var(--primary-color);
  --input-focused-placeholder-color: var(--primary-text-color);

  --svg-icon-fill: #7f8eac;
  --svg-icon-fill-primary: #fff;
  --svg-icon-fill-active: #1897f2;
  --svg-icon-fill-green: #03e191;
  --svg-icon-fill-red: #fa1d38;
  --svg-icon-fill-hover: #464b5c;

  --button-bckg-color: color(--white-color);
  --button-color: var(--primary-color);
  --button-color-hover: var(--primary-color);
  --button-color-active: var(--primary-color);
  --button-color-focus: var(--primary-color);
  --button-color-disabled: var(--disabled-text-color);
  --button-border: var(--border);
  --button-border-color-hover: var(--primary-color);
  --button-border-color-active: var(--primary-color);
  --button-border-color-focus: var(--primary-color);
  --button-border-outline: 3px solid #b3d4f5;
  --button-filter: var(--primary-filter);
  --button-bckg-color-active: var(--white-color);
  --button-bckg-color-hover: var(--white-color);
  --button-bckg-color-focus: var(--white-color);
  --button-bckg-color-disabled: var(--gray-4-color);

  --button-primary-bckg-color: var(--primary-color);
  --button-primary-bckg-color-hover: var(--secondary-color);
  --button-primary-color: var(--white-color);
  --button-primary-color-disabled: rgba(255, 255, 255, 0.5);
  --button-primary-link-color: var(--primary-color);
  --button-primary-border: 1px solid rgba(0, 0, 0, 0);
  --button-primary-filter: brightness(0) invert(1);

  --status-draft--bckg-color: rgba(238, 165, 82, 0.08);
  --status-draft--color: #eea552;
  --status-active--bckg-color: rgba(0, 101, 239, 0.08);
  --status-active--color: var(--clean-blue);
  --status-cancelled--bckg-color: rgba(153, 153, 153, 0.08);
  --status-cancelled--color: #999999;
  --status-completed--bckg-color: rgba(0, 131, 0, 0.08);
  --status-completed--color: #008300;
  --status-summarizing--bckg-color: rgba(255, 143, 58, 0.08);
  --status-summarizing--color: #ff8f3a;

  --status-member-active--bckg-color: rgba(0, 101, 239, 0.08);
  --status-member-active--color: var(--clean-blue);
  --status-member-blocked--bckg-color: rgba(230, 87, 87, 0.08);
  --status-member-blocked--color: #e65757;
  --status-member-deleted--bckg-color: #99999914;
  --status-member-deleted--color: #999999;

  --status-ticket-new--bckg-color: #0065ef14;
  --status-ticket-new--color: var(--clean-blue);
  --status-ticket-in-progress--bckg-color: #eea55214;
  --status-ticket-in-progress--color: #eea552;
  --status-ticket-declined--bckg-color: #99999914;
  --status-ticket-declined--color: #999999;
  --status-ticket-done--bckg-color: #00830014;
  --status-ticket-done--color: #008300;

  --modal-notification-bckg-color: var(--white-color);

  --navbar-height: 52px;

  // z-index
  --footer-z-index: 100;
  --select-portal-z-index: 2500;
  --modal-overlay-z-index: 2000;
  --loader-overlay-z-index: 100000;
}

/* Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--border-light-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--border-bright-color);
  cursor: pointer;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--border-light-color) rgba(0, 0, 0, 0);
}
