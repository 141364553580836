.create-proposal {
  display: flex;
  padding: 0 32px;
  flex-direction: column;
  &__row {
    display: flex;
    gap: 60px;
    justify-content: space-between;
    max-width: 1296px;
  }
  &__col {
    display: flex;
    flex-direction: column;
    flex-basis: 618px;
    gap: 24px;
  }
  &__required-hint {
    display: flex;
    margin: 28px 0 32px 0;
  }
  &__actions {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 32px;
  }
  &__comment-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  &__comment {
    height: 100%;
  }

  &__input-with-mask {
    display: inline;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    caret-color: var(--primary-text-color);
    background-image: linear-gradient(
      to right,
      var(--caption-text-color) 150px,
      var(--primary-text-color) 150px 100%
    );
  }
}

.modal-error {
  width: 424px;
}
