.custom-container {
  display: flex;
  padding: 16px;
  border: var(--container-border);
  border-radius: var(--border-radius);
  background-color: var(--block-background-color);

  &--full-width {
    flex: 1;
    width: 100%;
  }

  &--horizontal {
    flex-direction: row;
  }

  &--vertical {
    flex-direction: column;
  }
}

.custom-container-title {
  font-size: 20px;
  font-weight: 500;
}

.custom-container-field {
  &__row {
    font-weight: 500;
    line-height: 20px;

    &-value {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      word-wrap: break-word;
    }
  }
}
