.position-detail-proposals {
  &__grid {
    border-radius: 0;
    border: none;

    &-actions {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      button {
        white-space: nowrap;
      }
    }
  }
}
