@import '@shared/assets/styles/mixins';

.custom-rangedatepicker-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .custom-rangedatepicker-wrapper__error {
    @include error();
  }
  &--has-error {
    .custom-rangedatepicker-wrapper__label {
      color: var(--error-color);
    }
    input {
      border: var(--input-border-error) !important;
    }
    input:hover {
      border: var(--input-border-hover-error) !important;
    }
    input:focus {
      border: var(--input-border-focused-error) !important;
    }
  }
}

.custom-rangedatepicker {
  input {
    min-width: 320px;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px;
    gap: 12px;
    border: var(--input-border);
    font-family: 'Rubik' !important;
  }
  input::placeholder {
    color: var(--input-placeholder-color);
    letter-spacing: 0.25px;
  }
  input:focus-visible {
    border: 1px solid var(--input-focused-border-color);
    outline: none;
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 6px;
    top: 3px;
  }
  .react-datepicker__close-icon {
    right: 38px;
  }
  .react-datepicker__close-icon:after {
    background-color: transparent !important;
    content: url('../../../shared/icons/close.svg') !important;
    outline: none;
  }
  &--large {
    .react-datepicker__calendar-icon {
      top: 8px !important;
    }
    input {
      @include hugeHeight();
      font-size: 16px;
    }
    svg {
      width: 16px;
      height: 18px;
    }
  }
}
.custom-rangedatepicker-popper {
  padding-top: 0 !important;
  z-index: 10;
}

.custom-calendar-container {
  display: flex;
  flex-direction: column;
  width: 320px;
  min-height: 350px;
  border-radius: 8px;
  background-color: var(--white-color);
  @include dropdownShadow();
  padding: 20px 20px 0 20px;

  font-family: 'Rubik' !important;

  &__label {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
  }
  &__block {
    display: flex;
    flex-direction: column;

    gap: 12px;
  }
  &__block--spaced {
    margin-bottom: 24px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
  }
  &__button {
    height: 36px;
    padding: 10px 16px;
  }
  &__actions--divider {
    border-top: var(--container-border);
    margin-top: 24px;
  }
}
