.customers {
  display: flex;
  flex-direction: column;
  flex-grow: 0.85;
  &__actions {
    display: flex;
    gap: 12px;
    padding: 20px 0;
    flex-grow: 0;
  }
}