@import 'src/shared/assets/styles/variables';

#root {
    background-color: var(--background-color);
    height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: $layout-text-color;
  background-color: $layout-bg-color;
  min-height: 100vh;

  font-family: Rubik, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
