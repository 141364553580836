@import '@shared/assets/styles/mixins';

.dialogs {
  .chat {
    padding: 0;
    position: relative;
    height: 680px;

    .actions {
      @include actionButtons();
    }
    &__messages {
      height: 100%;
      padding: 0 0 0 26px;
      .ReactVirtualized__Grid.ReactVirtualized__List {
        padding-right: 20px;
      }
    }
    &__message-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
    }
  }
  .actions:has(#arrow-check:checked) ~ .linked-requests {
    height: auto;
    overflow-y: auto;
  }
  .actions:has(#arrow-check:not(:checked)) ~ .linked-requests {
    height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }

  #arrow-check ~ label:hover {
    cursor: pointer;
  }
  #arrow-check ~ label {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #arrow-check:not(:checked) ~ label > svg {
    transform: rotate(180deg);
  }
}
