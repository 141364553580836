.check-participants {
  width: 424px;
  max-height: 680px;
  background-color: var(--modal-notification-bckg-color);

  &__message {
    padding-bottom: 28px;
    margin: 0;
    font-size: 16px;
    line-height: 1.49em;
    letter-spacing: 0.25px;
    white-space: pre-line;
    word-wrap: break-word;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
  }

  &__actions {
    margin-top: 28px;
    gap: 16px;
  }
}
