.new-customer-bond-request {
  text-align: left;

  .page-content {
    gap: 16px;
  }

  &__row {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  &__input {
    position: relative;
    flex: 1;
  }

  &__label {
    width: 130px !important;
    flex: 1 0 130px;
  }

  &__label-radio {
    width: 130px;
    align-items: center;
  }

  &__block-title {
    margin-bottom: 0;
    font-size: 20px;

    &-parameters {
      margin-top: 32px;
    }
  }

  &__details-box {
    gap: 80px;
  }

  &__details,
  &__hint {
    flex: 1 1 50%;
    max-width: 608px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &__actual-hint {
    padding: 8px 16px;
    color: var(--caption-text-color);
    background-color: var(--gray-4-color);
  }

  &__actions {
    margin: 28px 0;
    display: flex;
    gap: 20px;
    & > .button {
      width: 220px;
    }
  }
}
