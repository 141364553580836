@import '@shared/assets/styles/mixins';

.custom-textarea {
  position: relative;

  .custom-tooltip {
    width: 100%;
  }
  &__field {
     position: relative;
  }
  &__field textarea {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 6px 20px 6px 12px;
    display: flex;
    border-radius: var(--border-radius);
    border: var(--input-border);
    resize: none;
    &:hover {
      border-color: var(--input-hover-border-color);
    }
    &:focus-within {
      border-color: var(--input-focused-border-color);
      background-color: var(--input-focused-bckg-color);
      outline: none;
    }
  }

  &--resizable textarea {
    resize: vertical;
  }

  &--empty &__floating-label {
    top: 10px;
  }

  &__wrapper-clear-button {
    position: absolute;
    cursor: pointer;
    top: 8px;
    right: 15px;
    z-index: 10;

    svg {
      fill: var(--svg-icon-fill);
      width: 10px;
      height: 10px;
    }

    &:hover svg {
      fill: var(--svg-icon-fill-hover);
      background: var(--input-bckg-color);
    }

    &:active svg,
    &:focus svg {
      background: var(--input-bckg-color);
    }
  }

  &--has-error {
    .custom-textarea__label,
    textarea {
      color: var(--error-color);
    }
    textarea {
      color: var(--error-color);
      border: var(--input-border-error);
    }
    textarea:focus {
      color: var(--error-color);
      border: var(--input-border-focused-error);
    }
    textarea:hover {
      color: var(--error-color);
      border: var(--input-border-hover-error);
    }
  }

  &__error {
    @include error();
  }

  :focus-visible {
    outline: none !important;
    box-shadow: none;
  }

  &--medium {
    .custom-textarea__container {
      @include mediumHeight();
    }
    textarea {
      font-size: 14px !important;
    }
  }
  &--large {
    .custom-textarea__container {
      @include hugeHeight();
    }
    textarea {
      font-size: 16px !important;
    }
  }
}
