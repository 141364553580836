.participants {
  display: flex;
  flex-direction: column;
  flex-grow: 0.85;
  &__actions {
    display: flex;
    gap: 12px;
    padding: 20px 0;
    flex-grow: 0;
  }
}

.participants-table-expandable-row {
  margin: 20px;
  padding: 20px;
  border: var(--container-border);
  border-radius: var(--border-radius);
  background-color: var(--gray-2-color) !important;

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__user {
    padding-top: 8px;
  }
}
