@mixin hugeHeight {
  height: 48px;
}
@mixin mediumHeight {
  height: 36px;
}

@mixin dropdownShadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 12px 0px rgba(0, 0, 0, 0.04) !important;
}

@mixin error($justify-content: flex-end) {
  position: absolute;
  right: 0;
  bottom: -24px;
  display: flex;
  justify-content: $justify-content;
  color: var(--error-color) !important;
}

@mixin actionButtons {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 16px;
  button {
    width: 36px;
    height: 36px;
    padding: 0;
    path {
      fill: var(--primary-color);
    }
  }
}
