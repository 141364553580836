.tickets-filters {
  display: flex;
  flex-grow: 1;
  gap: 24px;
  min-width: 1172px;

  &__range {
    width: 320px;
  }
  &__companies,
  &__statuses {
    width: 340px;
  }
  &__request-id {
    width: 20px;
  }
}
