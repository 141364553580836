.custom-breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;

  li {
    display: inline-flex;
    align-items: center;
    gap: 12px;

    a {
      color: var(--caption-text-color);
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }

      &.custom-breadcrumbs--inactive {
        pointer-events: none;
        text-decoration: none;
      }
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__chevron {
    width: 20px;
    height: 20px;
    padding: 4px;
    margin: 0 12px;
  }
}
