.custom-table {
  //открытие выпадающего
  .custom-expand {
    display: flex;
    &__button {
      margin-right: 8px;
    }
    &__arrow {
      width: 10px;
      &--rotate {
        transform: rotate(180deg);
      }
    }
  }
}
