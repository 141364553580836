.custom-footer-content {
  gap: 24px;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 32px 0 0;
  .custom-footer-copyright {
    color: var(--light-text-color);
  }

  a {
    text-decoration: none;
    color: var(--caption-text-color);
  }
  a:hover {
    text-decoration: underline;
  }
}

.custom-footer {
  z-index: var(--footer-z-index);
}
.custom-footer-notification {
  font-size: 14px;
  line-height: 20px;
  color: var(--caption-text-color);
  padding: 4px 16px;
  border: 1px solid var(--border-light-color);
}
.custom-footer-links {
  gap: 24px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
