.operator-filters {
  display: flex;
  flex-grow: 1;
  gap: 24px;
  min-width: 1172px;

  &__actions,
  &__request-id,
  &__user-names {
    width: 310px;
  }
}
