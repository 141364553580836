.custom-badge {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &--large {
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &--small {
    height: 20px;
    padding: 2px 12px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  &--tiny {
    height: 13px;
    border-radius: 8px;
    padding: 1.34px 8.04px;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
  }
}
