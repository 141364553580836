.new-request {
  &__position-content,
  &__position-block {
    display: flex;
    align-items: center;
  }
  &__position-content {
    gap: 100px;
  }
  &__position-block {
    gap: 24px;
  }
  &__position-row {
    margin-bottom: 24px;
    padding-left: 12px;
  }
  &__position-row::marker {
    font-weight: 500;
    font-size: 14px;
  }
  &__position-period_type,
  &__position-period,
  &__position-date {
    width: 190px;
  }
  &__position-date + .custom-datepicker-wrapper__error {
    width: max-content;
  }
  &__position-amount {
    width: 435px;
  }
  &__position-clear {
    margin-left: -8px;
    border: none;
    svg {
      width: 10px;
      height: 10px;
      fill: var(--primary-text-color);
    }
  }
}
