.custom-chat {
  &__title {
    text-align: left;
    margin-bottom: 24px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }
  &__submit {
    width: 140px;
  }
  &__textarea {
    height: 96px;
  }
  &__messages {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0;
  }
  &__no-messages {
    text-align: left;
    color: var(--caption-text-color);
    margin-bottom: 24px;
  }
  &__closing {
    color: var(--caption-text-color);
    flex: 1;
    background-color: var(--gray-2-color);
    border-radius: 8px;
    padding: 16px;
  }
}

.custom-message {
  &__avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  &__author {
    font-weight: 500;
    &--operator {
      color: var(--clean-blue);
    }
  }
  &__text {
    display: flex;
    text-align: left;
    padding-left: 56px;
    line-height: 24px;
  }
}
