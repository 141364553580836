@import '@shared/assets/styles/variables';

.new-ticket-page {
  text-align: left;

  &__form {
    margin-top: 16px;
  }

  &__block-title {
    font-size: 20px;
    margin-bottom: 0;
  }
  &__message {
    line-height: 24px;
  }
  &__details-box {
    gap: 80px;
  }

  &__fields-wrap {
    width: $block-width;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__textarea {
    height: 168px;
  }

  &__actions {
    display: flex;
    gap: 20px;
    margin-top: 28px;
    button {
      width: 220px;
      padding: 0;
    }
  }
}

.new-ticket-hint ol li {
  margin-bottom: 8px;
}
