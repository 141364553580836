.custom-table .custom-tbody {
  height: 100%;
  color: var(--primary-text-color);
  overflow-x: auto;
  //чередование
  .custom-tbody__tr:nth-of-type(2n) {
    background-color: var(--gray-3-color);
  }
  //бордер между строками
  &__tr {
    background-color: var(--white-color);
    min-height: 66px;

    &--clickable {
      cursor: pointer;
    }
  }
  &__td {
    padding: 12px 24px;
    &--bordered {
      border-right: var(--container-border);
      border-bottom: var(--container-border);
    }
  }
}
//позиционирование в ячейке
.custom-cell {
  display: inline-flex;
  word-break: break-word;
  &--left {
    justify-content: flex-start;
  }
  &--center {
    justify-content: center;
  }
  &--right {
    justify-content: flex-end;
  }
  &--top {
    align-items: flex-start;
  }
  &--middle {
    align-items: center;
  }
  &--bottom {
    align-items: flex-end;
  }

  &--fixed {
    position: sticky;
    right: 0;
    background: unset;
  }
}
