.communication-create-dialog-modal {
  width: 360px;
  max-height: 820px;
  p {
    margin: 0;
  }
  &__title {
    font-size: 20px;
    margin-bottom: 16px;
  }
  &__content {
    display: grid;
    grid-template: 'a b' 'c d';
    row-gap: 16px;
    border-radius: 8px;
    padding: 16px;
    background-color: var(--gray-2-color);
    border: 1px solid var(--border-light-color);
    margin-bottom: 16px;
  }
  &__field {
    margin-bottom: 24px;
    textarea {
      min-height: 36px;
      max-height: 470px;
      resize: vertical;
    }
  }
  &__recipient-label {
    grid-area: a;
    font-weight: 500;
    width: 100px;
  }
  &__recipients {
    grid-area: b;
    font-weight: 500;
  }
  &__request-label {
    grid-area: c;
    width: 100px;
  }
  &__requests {
    grid-area: d;
  }
}
