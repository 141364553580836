@import '@shared/assets/styles/mixins';

.custom-calendar-datetime-container {
  display: flex;
  flex-direction: column;
  height: 180px;
  border-radius: 8px;
  background-color: var(--white-color);
  @include dropdownShadow();
  padding: 20px;
  gap: 8px;
  font-family: 'Rubik' !important;

  &__input {
    width: 260px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0px;
    gap: 8px;

    &--divider {
      border-top: var(--container-border);
      margin-top: 24px;
    }
  }

  &__button {
    height: 36px;
    padding: 10px 16px;
  }
}
