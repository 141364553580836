.add-company-modal {
  width: 360px;
  height: 380px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  &__select-label {
    font-size: 14px;
  }
  &__label {
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    &--disabled {
      opacity: 0.8;
      pointer-events: none;
    }
  }
}
