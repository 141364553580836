.requests {
  display: flex;
  flex-direction: column;
  flex-grow: 0.85;
  &__actions {
    display: flex;
    gap: 12px;
    padding: 20px 0;
  }
  .time {
    color: var(--caption-text-color);
    text-align: right;
  }

  .proposal-status {
    &--cancelled {
      color: var(--disabled-text-color);
    }
  }

  .creator {
    display: flex;
    flex-direction: column;
    &__name {
      color: var(--caption-text-color);
    }
  }
}
