.user-ticket-detail {
  $columnWidth: 608px;

  .page-content {
    gap: 16px;
  }
  &__row {
    display: flex;
    gap: 60px;
  }
  &__chat {
    flex-basis: $columnWidth;
  }
  &__col {
    display: flex;
    flex-direction: column;
    flex-basis: $columnWidth;
    gap: 20px;
    text-align: left;
  }

  &__title {
    text-align: left;
  }
}
