.operator-requests-filters {
  display: flex;
  flex-grow: 1;
  gap: 24px;
  min-width: 1172px;

  &__request-id,
  &__statuses,
  &__companies {
    width: 260px;
  }
}
