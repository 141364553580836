.ticket-comment-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  label {
    font-weight: 500;
    line-height: 20px;
  }
  label,
  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .custom-textarea {
    width: 100%;
    margin-top: 12px;
  }

  &__close-icon {
    transform: rotate(45deg);
  }

  &__comment {
    text-align: left;
  }
}
