.custom-table .custom-thead {
  font-size: 14px;
  line-height: 20px;
  color: var(--caption-text-color);
  background-color: var(--gray-3-color);
  line-height: 16px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &__th {
    font-weight: 400;
    z-index: 2;
    display: flex;
    align-items: center;
    font-style: normal;
    line-height: 20px;
    padding: 12px 24px;
    &--bordered {
      border-right: var(--container-border);
      border-bottom: var(--container-border);
    }
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
