.dialogs-filters {
  display: flex;
  gap: 24px;

  &__field {
    width: 320px;
  }
  &__search {
    width: 300px;
    margin-left: auto;
  }
}
