@import 'src/shared/assets/styles/variables';

.custom-info-box {
  text-align: left;
  gap: 24px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &__row {
    display: flex;
    gap: 60px;
  }
  &__col {
    display: flex;
    flex-basis: $block-width;
    flex-direction: column;
    gap: 20px;
  }
}
