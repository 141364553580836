.participant-bond-detail {
  &-info {
    flex: 0;
  }
  .page-content {
    gap: 16px;
  }
  &-extra {
    display: flex;
    gap: 16px;
  }
}
