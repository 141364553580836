.custom-pills {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &--vertical {
    .custom-pills__list {
      flex-direction: column;
      gap: 8px;
    }
  }
  &__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    gap: 24px;

    &-item {
      &-link {
        padding: 8px 12px;
        color: var(--caption-text-color);
        text-decoration: none;
        color: var(--primary-text-color);
        border-radius: 8px;

        &:hover {
          color: var(--primary-text-color);
          background-color: var(--gray-4-color);
        }
        &.active {
          color: var(--primary-color);
          background-color: var(--gray-4-color);
          font-weight: 500;
        }
        &.disabled {
          background-color: transparent;
          color: var(--font-color-disabled);
          pointer-events: none;
        }
      }
    }
  }
}
