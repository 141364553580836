.custom-scrollbar {
  &__vertical-track {
    width: 8px !important;
    border-radius: 4px;
  }
  &__horizontal-track {
    height: 4px !important;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 4px;
  }
  &__thumb {
    background-color: var(--border-light-color) !important;
    cursor: pointer !important;
  }
  > div:first-child {
    margin-bottom: 0 !important;
    max-height: inherit !important;
    overflow-x: hidden !important;
    height: 100%;
  }
}
