@import '@shared/assets/styles/mixins';

.custom-checkbox {
  position: relative;

  &.checkbox-wrapper {
    align-items: center;
  }

  input[type='checkbox'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--checkbox-bckg-color);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
  }
  input[type='checkbox'] + label {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
  }
  &__text {
    width: calc(100% - 20px);
    text-align: left;
  }
  // box
  input[type='checkbox'] + label::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    margin-right: 8px;
    border: solid 1px; /* Safari */
    border-color: var(--checkbox-border-color);
    border-radius: 4px;
    width: 20px;
    height: 20px;
    vertical-align: top;
  }
  /* Checkmark */
  input[type='checkbox'] + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: 4px;
    width: 12px;
    height: 8px;
    background-color: transparent;
    border: 2px solid transparent;
    border-right: none;
    border-top: none;
    transform: rotate(-45deg);
  }

  input:checked + label::before {
    border-color: var(--checkbox-border-color);
    background-color: var(--checkbox-bckg-color);
  }

  input:checked + label::after {
    border-color: var(--checkbox-dot-color);
  }
  /* стили при наведении курсора на checkbox */
  input:not(:disabled) + label:hover::before {
    border-color: var(--checkbox-border-hover-color);
  }
  /* стили для активного состояния чекбокса (при нажатии на него) */
  input:not(:disabled):active + label::before {
    border-color: var(--checkbox-border-checked-color);
    box-shadow: var(--checkbox-focused-shadow);
  }
  /* стили для чекбокса, находящегося в фокусе */
  input:focus + label::before {
    box-shadow: var(--checkbox-focused-shadow);
  }
  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  input:focus:not(:checked) + label::before {
    box-shadow: var(--checkbox-focused-shadow);
  }
  /* стили для чекбокса, находящегося в состоянии disabled */
  input:disabled + label::before {
    background-color: var(--checkbox-bckg-color-disabled);
    border: none;
  }

  input:disabled + label::before {
    background-color: var(--checkbox-bckg-color-disabled);
    border: none;
  }
  input:disabled:checked + label::after {
    background-color: transparent;
    border-color: var(--white-color);
  }

  &--error {
    color: var(--checkbox-error-color);
    input[type='checkbox'] + label::before,
    input[type='checkbox']:hover + label::before {
      border-color: var(--checkbox-error-color) !important;
    }
    input[type='checkbox'] + label::after {
      border-color: var(--checkbox-error-color);
    }
    input:focus + label::before {
      box-shadow: var(--checkbox-focused-error-shadow);
    }
    .custom-checkbox__error {
      @include error();
    }
  }

  &--right {
    flex-direction: row;
  }

  &--left {
    flex-direction: row-reverse;
  }

  &--disabled {
    cursor: default;
  }
}
